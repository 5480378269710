.landing_navbar {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  z-index: 1000;
  padding: 75px 75px;
  padding-left: 30px;
  padding-right: 45px;
  padding-top: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
}

.landing_navbar {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  z-index: 1000;
  padding: 75px 75px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
}

.menu_items {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.logo_box {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.logo {
  margin-left: 0px;
  height: 40px;
  object-fit: cover;
  cursor: pointer;
  margin-right: 50px;
}

.mobile_logo {
  margin-left: 0px;
  height: 40px;
  object-fit: cover;
  cursor: pointer;
  margin-right: 50px;
}

.menu_options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}

.menu_item {
  color: white;
  font-size: 20px;
  font-weight: 300;
  margin: 0;
}

.landing_page_layout {
  width: 100vw;
  height: 100vh;
}

.button_options {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_text {
  font-size: 18px;
  color: #003d82;
}

.login_text:hover {
  opacity: 0.7;
  cursor: pointer;
}

.login_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  height: 40px;
  padding: 0 25px;
  font-weight: 200;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0);
  background-color: white;
  color: black;
  box-sizing: border-box;
}

.login_button:hover {
  color: white;
  background-color: black;
  border: 1px solid rgba(255, 255, 255, 1);
}

.header_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #003d82;
  width: 100%;
  height: 45px;
}

.header_text {
  color: white;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.menu_icon {
  height: 30px;
  width: 30px;
  object-fit: cover;
  cursor: pointer;
}

.navbar_menu {
  background-color: black;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 50px;
}

.navbar_menu_content {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 75px;
  gap: 50px;
}

.menu_item_mobile {
  color: white;
  font-size: 26px;
  font-weight: 200;
  margin: 0;
}

.hamburger {
  height: 50px;
  width: 50px;
  transform: 0.2s;
  position: relative;
}

.checkbox {
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.line {
  transition: 0.5s;
  stroke-width: 1px;
  stroke: white;
}

.lineTop,
.lineMid,
.lineBottom {
  stroke-dasharray: 40 40;
}

.lineTop {
  stroke-dashoffset: 25;
}

.lineBottom {
  stroke-dashoffset: 60;
}

.checkbox:checked + svg .line {
  stroke: white;
}

.checkbox:checked + svg .lineTop {
  stroke-dashoffset: 0;
  transform-origin: left;
  transform: rotateZ(45deg) translate(-7px, -5px);
}

.checkbox:checked + svg .lineMid {
  stroke-dashoffset: 40;
}

.checkbox:checked + svg .lineBottom {
  stroke-dashoffset: 0;
  transform-origin: left;
  transform: rotateZ(-45deg) translate(-5px, 5px);
}
