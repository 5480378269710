.white_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
  /* width: 100%; */
  height: 400px;
  background-color: white;
  z-index: 9999;
  padding: 75px;
  box-sizing: border-box;
}

.white_section_why {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  /* width: 100%; */
  height: 500px;
  background-color: white;
  z-index: 9999;
  box-sizing: border-box;
}

.lorcidon_stroke {
  stroke: 0.5;
}

.lottie-container {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.white_section_large {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: row;
  position: relative;
  /* width: 100%; */
  /* height: 800px; */
  background-color: white;
  z-index: 9999;
  padding: 75px;
  box-sizing: border-box;
}

.left_section {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 90%;
}

.target_section {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* padding-top: 50px;
  padding-left: 100px; */
  /* background-color: red; */
  width: 100%;
  height: 450px;

  /* width: 100%;
  height: 550px; */
  /* height: 300px; */
}

.right_section {
  width: 50%;
  height: 100%;
}

.step_divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 50px 0;
}

.approach_step {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.step_number {
  display: flex;
  align-items: start;
  flex-direction: column;
  margin: 0;
  font-size: 25px;
  font-weight: 200;
}

.step_title {
  display: flex;
  align-items: start;
  flex-direction: column;
  margin: 0;
  font-size: 35px;
  font-weight: 500;
}

.step_description {
  display: flex;
  align-items: start;
  flex-direction: column;
  text-align: start;
  margin: 0;
  font-size: 20px;
  font-weight: 200;
}

.step_number {
  margin: 0;
  color: black;
}

.banner_title_box {
  width: 58%;
  box-sizing: border-box;
}

.landing_statistics {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  box-sizing: border-box;
}

/* Define styles for the grid items */
.landing_stat {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 150px;
  border-radius: 5px;
}

.landing_stat_layout {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  width: 70%;
}

.landing_stat_value {
  color: black;
  margin: 0;
  font-size: 50px;
  font-weight: 400;
}

.landing_stat_title {
  color: black;
  margin: 0;
  font-size: 25px;
  font-weight: 200;
}

.black_section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  /* height: 100vh; */
  background-color: black;
  z-index: 9999;
  padding: 75px;
  box-sizing: border-box;
}

.section_top_layout {
  display: flex;
  flex-direction: column;
  align-items: start;

  width: 100%;
  color: white;
  margin-bottom: 40px;
}

.animated_horizontal_divider_end {
  display: flex;
  width: 100%;
  justify-content: end;
}

.animated_horizontal_divider_start {
  display: flex;
  width: 100%;
  justify-content: start;
}

.animated_horizontal_black_divider_inverse_left {
  height: 2px;
  width: 300px;
  background-color: white;
}

.animated_horizontal_black_divider_inverse_right {
  height: 2px;
  width: 300px;
  background-color: white;
}

.divider_layout {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.black_divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 300px;
}

.section_marker {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
}

.marker_title {
  margin: 0;
  color: white;
  font-size: 20px;
  font-weight: 400;
}

.marker_title_black {
  margin: 0;
  color: black;
  font-size: 20px;
  font-weight: 400;
}

.section_top_title {
  margin: 0;
  color: white;
  font-size: 70px;
  font-weight: 200;
  margin-bottom: 20px;
  text-align: start;
}

.section_top_title_black {
  margin: 0;
  color: black;
  font-size: 70px;
  font-weight: 200;
  margin-bottom: 50px;
}

.star_icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 12.5px;
}

.section_sector_content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: start;
  width: 100%;
}

.investment_sector {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 33%;
}

.investment_sector_layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.investment_sector_title {
  color: white;
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  text-align: start;
  margin-bottom: 10px;
  align-self: start;
}

.investment_sector_description {
  color: white;
  margin: 0;
  text-align: center;
  font-size: 24px;
  font-weight: 200;
  text-align: start;
}

.sector_image {
  width: 50px;
  height: 50px;
}

/* .vertical_divider_left {
  width: 1px;
  height: 300px;
  background-color: white;
  margin: 50px;
  margin-top: 0;
}

.vertical_divider_right {
  width: 1px;
  height: 300px;
  background-color: white;
  margin: 50px;
  margin-top: 0;
} */

.vertical_divider {
  width: 1px;
  height: 300px;
  background-color: white;
  margin: 50px;
  margin-top: 0;
}

.banner_title {
  color: black;
  margin: 0;
  font-size: 60px;
  font-weight: 200;
  text-align: start;
}

.banner_content_text {
  color: black;
  margin: 0;
  font-size: 30px;
  font-weight: 200;
  margin-top: 30px;
  margin-bottom: 40px;
}

.white_section_apply {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  /* width: 100%; */
  height: 650px;
  background-color: white;
  z-index: 9999;
  padding: 75px;
  box-sizing: border-box;
}

.apply_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.apply_section_tagline {
  font-size: 40px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;
}

.apply_section_text {
  font-size: 80px;
  font-weight: 100;
  margin: 0;
}

.apply_section_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 225px;
  height: 75px;
  border-radius: 75px;
  background-color: black;
  color: white;
  margin-top: 30px;
  font-size: 25px;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.apply_section_button:hover {
  background-color: white;
  color: black;
  border: 1px solid rgba(0, 0, 0, 1);
}

.black_section_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
  /* width: 100%; */
  height: 600px;
  background-color: black;
  z-index: 9999;
  padding: 75px;
  box-sizing: border-box;

  /* border-bottom: 10px  solid green; */
}

.black_section_content_extended {
  background-color: black;
}

.black_section_content_left {
  width: 50%;
}

.black_section_content_right {
  width: 50%;
}

.glass_image {
  height: 300px;
}

.white_spacer {
  height: 1wpx;
}

.black_section_content_title {
  color: white;
  margin: 0;
  font-size: 55px;
  margin-bottom: 20px;
  text-align: start;
}

.black_section_content_text {
  color: white;
  margin: 0;
  font-size: 30px;
  font-weight: 200;
  text-align: start;
}

.black_section_content_title_end {
  color: white;
  margin: 0;
  font-size: 55px;
  margin-bottom: 20px;
  text-align: end;
}

.black_section_content_text_end {
  color: white;
  margin: 0;
  font-size: 30px;
  font-weight: 200;
  text-align: end;
}

/* ------------------------- */

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 400px;
  background-color: white;
  z-index: 9999;
}

.banner_black {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
  width: 100%;
  height: 400px;
  background-color: black;
  /* clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%); */
  z-index: 9999; /* Optional: this may be necessary if you want this element to be on top of others */
  margin-bottom: 30px;
}

.abstractWhite {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.banner_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
}

.banner_title_white {
  color: white;
  margin: 0;
  font-size: 30px;
  font-weight: 600;
}

.banner_content_text_white {
  color: white;
  margin: 0;
  font-size: 20px;
  font-weight: 200;
  margin-top: 30px;
  margin-bottom: 40px;
}

.banner_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  counter-reset: #e50019;
  color: #e50019;
  border-radius: 5px;
  width: 150px;
  height: 55px;
  cursor: pointer;
}

.banner_button:hover {
  background-color: #e50019;
  color: white;
}

.section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  /* height: 550px; */
}

/* --------------------------------- ANIMATIONS --------------------------------- */

@keyframes expandRight {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes expandLeft {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.divider_animate_right {
  animation: expandRight 2s forwards;
}

.divider_animate_left {
  animation: expandLeft 2s forwards;
}

.section_full_top_layout {
  display: flex;
  align-items: start;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.animated_search_section {
  margin-top: -40px;
  margin-right: 40px;
}

/* --------------------------------- MOBILE PHONE --------------------------------- */

@media (max-width: 1024px) {
  .white_section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: white;
    height: 400px;
    z-index: 9999;
    padding: 25px;
    box-sizing: border-box;
  }

  .white_section_why {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    /* width: 100%; */
    /* height: 500px; */
    height: 700px;
    background-color: white;
    z-index: 9999;
    box-sizing: border-box;
  }

  .lorcidon_stroke {
    stroke-width: 0.5;
  }

  .white_section_large {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    position: relative;
    background-color: white;
    z-index: 9999;
    padding: 50px 25px;
    box-sizing: border-box;
  }

  .left_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 90%;
  }

  .target_section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 105px;
    width: 105px;
    /* width: 100%;
    height: 550px; */
  }

  .right_section {
    width: 100%;
    height: 100%;
  }

  .step_divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.3);
    margin: 30px 0;
  }

  .approach_step {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .step_number {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin: 0;
    font-size: 25px;
    font-weight: 200;
  }

  .step_title {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin: 0;
    font-size: 35px;
    font-weight: 500;
  }

  .step_description {
    display: flex;
    align-items: start;
    flex-direction: column;
    text-align: start;
    margin: 0;
    font-size: 20px;
    font-weight: 200;
  }

  .step_number {
    margin: 0;
    color: black;
  }

  .banner_title_box {
    width: 100%;
    box-sizing: border-box;
  }

  .landing_statistics {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    box-sizing: border-box;
  }

  /* Define styles for the grid items */
  .landing_stat {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 150px;
    border-radius: 5px;
  }

  .landing_stat_layout {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    width: 70%;
  }

  .landing_stat_value {
    color: black;
    margin: 0;
    font-size: 50px;
    font-weight: 400;
  }

  .landing_stat_title {
    color: black;
    margin: 0;
    font-size: 25px;
    font-weight: 200;
  }

  /* .white_section_large {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    position: relative;
    background-color: white;
    z-index: 9999;
    padding: 50px 25px;
    box-sizing: border-box;
  } */

  .black_section {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    position: relative;
    background-color: black;
    z-index: 9999;
    padding: 50px 25px;
    box-sizing: border-box;
  }

  .section_top_layout {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    color: white;
    margin: 0;
  }

  /* .divider_layout {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 75px;
  }

  .black_divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.5);
  } */

  .section_marker {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 15px;
  }

  .marker_title {
    margin: 0;
    color: white;
    font-size: 15px;
    font-weight: 400;
  }

  .marker_title_black {
    margin: 0;
    color: black;
    font-size: 20px;
    font-weight: 400;
  }

  .section_top_title {
    margin: 0;
    color: white;
    font-size: 20px;
    font-weight: 200;
    margin-top: -10px;
    margin-bottom: 50px;
    text-align: start;
    width: 50vw;
  }

  .section_top_title_black {
    margin: 0;
    color: black;
    font-size: 20px;
    font-weight: 200;
    margin-top: -10px;
    margin-bottom: 50px;
    text-align: start;
    width: 50vw;
  }

  .star_icon {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 12.5px;
  }

  .section_sector_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .investment_sector {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
  }

  .investment_sector_layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .investment_sector_title {
    color: white;
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    text-align: start;
    margin-bottom: 10px;
    align-self: start;
  }

  .investment_sector_description {
    color: white;
    margin: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 200;
    text-align: start;
  }

  .sector_image {
    width: 50px;
    height: 50px;
  }

  .vertical_divider {
    height: 1px;
    width: 100%;
    background-color: white;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .banner_title {
    color: black;
    margin: 0;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
  }

  .banner_content_text {
    color: black;
    margin: 0;
    font-size: 22px;
    font-weight: 200;
    /* margin-top: 30px;
    margin-bottom: 40px; */
  }

  .white_section_apply {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    /* width: 100%; */
    height: 550px;
    background-color: white;
    z-index: 9999;
    padding: 15px;
    box-sizing: border-box;
  }

  .apply_layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .apply_section_tagline {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
  }

  .apply_section_text {
    font-size: 40px;
    font-weight: 100;
    margin: 0;
    margin-bottom: 10px;
  }

  .apply_section_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 55px;
    border-radius: 75px;
    background-color: black;
    color: white;
    margin-top: 25px;
    font-size: 25px;
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .apply_section_button:hover {
    background-color: white;
    color: black;
    border: 1px solid rgba(0, 0, 0, 1);
  }

  .black_section_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    position: relative;
    /* width: 100%; */
    /* height: 600px; */
    height: 400px;
    background-color: black;
    z-index: 9999;
    padding: 25px;
    box-sizing: border-box;

    /* border-bottom: 10px  solid green; */
  }

  .black_section_content_extended {
    background-color: black;
  }

  .black_section_content_left {
    width: 70%;
  }

  .black_section_content_right {
    width: 50%;
  }

  .glass_image {
    height: 100px;
  }

  .white_spacer {
    height: 1wpx;
  }

  .black_section_content_title {
    color: white;
    margin: 0;
    font-size: 22px;
    margin-bottom: 10px;
    text-align: start;
  }

  .black_section_content_text {
    color: white;
    margin: 0;
    font-size: 16px;
    font-weight: 200;
    text-align: start;
  }

  .black_section_content_title_end {
    color: white;
    margin: 0;
    font-size: 22px;
    margin-bottom: 10px;
    text-align: end;
  }

  .black_section_content_text_end {
    color: white;
    margin: 0;
    font-size: 16px;
    font-weight: 200;
    text-align: end;
  }

  /* ------------------------- */

  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 400px;
    background-color: white;
    z-index: 9999;
  }

  .banner_black {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-top: 50px;
    width: 100%;
    height: 400px;
    background-color: black;
    /* clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%); */
    z-index: 9999; /* Optional: this may be necessary if you want this element to be on top of others */
    margin-bottom: 30px;
  }

  .abstractWhite {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .banner_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    width: 82%;
  }

  .banner_title_white {
    color: white;
    margin: 0;
    font-size: 30px;
    font-weight: 600;
  }

  .banner_content_text_white {
    color: white;
    margin: 0;
    font-size: 20px;
    font-weight: 200;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .banner_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    counter-reset: #e50019;
    color: #e50019;
    border-radius: 5px;
    width: 150px;
    height: 55px;
    cursor: pointer;
  }

  .banner_button:hover {
    background-color: #e50019;
    color: white;
  }

  .section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
    /* height: 550px; */
  }

  .animated_horizontal_divider_end {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .animated_horizontal_divider_start {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .animated_horizontal_black_divider_inverse_left {
    height: 2px;
    width: 300px;
    background-color: white;
  }

  .animated_horizontal_black_divider_inverse_right {
    height: 2px;
    width: 300px;
    background-color: white;
  }

  .animated_search_section {
    margin-top: 0px;
    margin-right: 0px;
  }
}

.apply_section_blurbs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.divider {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  height: 1px;
}

.blurb {
  font-size: 25px;
  font-weight: 200;
  margin: 0;
}
