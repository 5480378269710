.home_components {
  width: 100vw;
  overflow: hidden;
  height: 105vh;
  background-color: white;
  position: relative;
  animation: adjustHeight 1s ease forwards;
  animation-delay: 3s;
}

@keyframes adjustHeight {
  to {
    height: 95vh;
  }
}

.landing_image {
  width: 100vw;
  background-color: white;
  overflow: hidden;
  object-fit: cover;
  object-position: bottom center;
  position: absolute;
}

.black_overlay {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 100%;
  left: 0;
  animation: expand 0.75s ease forwards;
  animation-delay: 3s;
}

@keyframes expand {
  to {
    top: 0; /* Expand the black overlay to cover the entire screen */
  }
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
}

.home_header_content {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
  /* border-radius: 10px; */
}

.landing_header {
  /* background: rgba(255, 255, 255, 0.5); */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 50px;
}

.main_copywriting {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.title_copywriting {
  color: black;
  font-size: 95px;
  text-align: center;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 100;
  animation: fadeTitle 1s ease forwards, moveUp 1s ease forwards;
  animation-delay: 3s;
}

.subtitle_copywriting {
  color: white;
  font-size: 30px;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  opacity: 0;
  animation: fadeIn 1s ease forwards, moveUp 1s ease forwards;
  animation-delay: 3s;
}

.buttons {
  display: flex;
  flex-direction: row;
  height: 75px;
  justify-content: space-between;
  margin-top: 10px;
  gap: 30px;
  opacity: 0;
  animation: fadeIn 1s ease forwards, moveUp 1s ease forwards;
  animation-delay: 3s;
}

.trial_button_text {
  font-size: 22px;
}
.trial_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 100%;
  color: white;
  /* background-color: #E50019; */
  border: 1px solid white;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 30px;
}

.trial_button:hover {
  color: black;
  background-color: white;
  cursor: pointer;
}

.flickerLetter {
  display: inline-block;
  animation-name: flickerAnimation;
  animation-duration: 0.1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fadeTitle {
  0% {
    color: black;
  }
  50% {
    color: silver;
  }
  100% {
    color: white;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes moveUp {
  to {
    transform: translateY(-60px);
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* --------------------------------- MOBILE --------------------------------- */

@media (max-width: 1024px) {
  .title_copywriting {
    font-size: 30px;
  }

  .subtitle_copywriting {
    font-size: 18px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    height: 75px;
    justify-content: space-between;
    margin-top: 10px;
    gap: 10px;
  }

  .home_components {
    width: 100vw;
    overflow: hidden;
    height: 105vh;
    background-color: white;
    position: relative;
    animation: adjustHeight 1s ease forwards;
    animation-delay: 3s;
  }

  @keyframes adjustHeight {
    to {
      height: 95vh;
    }
  }

  .landing_image {
    width: 100vw;
    background-color: white;
    overflow: hidden;
    object-fit: cover;
    object-position: bottom center;
    position: absolute;
  }

  .black_overlay {
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
    top: 100%;
    left: 0;
    animation: expand 0.75s ease forwards;
    animation-delay: 3s;
  }

  @keyframes expand {
    to {
      top: 0; /* Expand the black overlay to cover the entire screen */
    }
  }

  .cover_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
  }

  .home_header_content {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
    /* border-radius: 10px; */
  }

  .landing_header {
    /* background: rgba(255, 255, 255, 0.5); */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* margin-top: 30px; */
  }

  .main_copywriting {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .trial_button_text {
    font-size: 18px;
  }
  .trial_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 275px;
    height: 100%;
    color: white;
    /* background-color: #E50019; */
    border: 1px solid white;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 30px;
  }

  .trial_button:hover {
    color: black;
    background-color: white;
    cursor: pointer;
  }

  .flickerLetter {
    display: inline-block;
    animation-name: flickerAnimation;
    animation-duration: 0.1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
}
